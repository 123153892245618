<template>
<!-- 合同文件审核tab页 -->
  <div class="contractFileReview">
    <base-tabs type="border-card" v-model="activeNames" class="tabs"  @click="hander">
      <base-tab-pane label="待审核" name="1">
        <content-main :activeNames="activeNames" :gysData='gysData' v-if="activeNames == '1'"></content-main>
      </base-tab-pane>
      <base-tab-pane label="审核通过" name="2">
        <content-main :activeNames="activeNames" :gysData='gysData' v-if="activeNames == '2'"></content-main>
      </base-tab-pane>
      <base-tab-pane label="返回修改" name="3">
        <content-main :activeNames="activeNames" :gysData='gysData' v-if="activeNames == '3'"></content-main>
      </base-tab-pane>
    </base-tabs>
  </div>
</template>
<script>
import BaseTabs from '@/components/common/tabs/base-tabs/base-tabs.vue'
import BaseTabPane from '@/components/common/tabs/base-tab-pane/base-tab-pane.vue'
import ContentMain from './financing-application-examine-main.vue'
import Storage from '@/utils/storage'
export default {
  name: 'contractFileReview',
  components: { BaseTabs, BaseTabPane, ContentMain },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        financingCode: '', // 业务编号
        supplier: '' // 供应商
      },
      activeNames: '1',
      tableData: [],
      gysData: []
    }
  },
  computed: {
  },
  mounted () {
    if (Storage.getSession('activeNames')) {
      this.activeNames = Storage.getSession('activeNames')
    }
  },
  activated () {
    if (Storage.getSession('activeNames')) {
      this.activeNames = Storage.getSession('activeNames')
    }
  },
  methods: {
    hander () {
      Storage.setSession('activeNames', this.activeNames)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-tabs--border-card{
  border: 0;
  box-shadow: none;
}
/deep/ .el-tabs__content{
  padding: 0 !important;
}

.contractFileReview {
  height: 100%;
   .tabs{
    margin-top:10px ;
  }
}
</style>
